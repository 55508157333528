import React, { useEffect, useState } from 'react';
import "../Css/ClothCard.css";

const ClothCard = ({ pPrice, pImage, pName, pImagesSecond, pDiscount }) => {
  const [backgroundImage, setBackgroundImage] = useState(`url(${pImage})`);

  const handleMouseEnter = () => {
    setBackgroundImage(`url(${pImagesSecond})`);
  };

  const handleMouseLeave = () => {
    setBackgroundImage(`url(${pImage})`);
  };

  const calculateOfferPrice = (price, discount) => {
    return Math.round(price * (1 - discount / 100));
  };

  useEffect(() => {
    setBackgroundImage(`url(${pImage})`);
  }, []);

  return (
    <>
      <div className='producct-main-card-body-con'>
        <div className='producct-main-card-body'>
          <div
            className="main-card-product reletive"
            style={{
              backgroundImage,
              width: "100%",
              backgroundSize: "cover",
              margin: "10px 0px",
              backgroundPosition: "center"
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <a className='card-product-shop-now'>
              Shop Now
            </a>
            {
              pDiscount == 45 ?
                (
                  <>
                    <div className='new-tag'>
                      <h3>New</h3>
                    </div>
                  </>
                ) :
                null
            }

          </div>

          <div className='clothCard-product-deatils-main-con'>
            <span className='clothCard-product-title'>
              {pName}
            </span>
            <div className='clothCard-product-deatils-main-con-price'>
              <div className='clothCard-product-deatils-main-con-mainprice'>
                <label> RS. {calculateOfferPrice(pPrice, pDiscount)} INR </label>
              </div>
              <div className='clothCard-product-deatils-main-con-offerprice'>
                <label> RS. {pPrice} INR </label>
              </div>
            </div>
            <div className='clothCard-product-detials-main-con-offer-per'>
              <h4>({pDiscount}% OFF)</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClothCard;
