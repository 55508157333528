import React, { useEffect, useState } from 'react';
import "../../Css/ProfileEdit.css";
import axios from 'axios';
import { Button, Form, Input, Modal, message } from 'antd';
import { post } from '../../apiService';

const ProfileEdit = () => {
    const [form] = Form.useForm();
    const tokenid = localStorage.getItem("access_token");
    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userProfile,setUserProfile]= useState()
    const [profileData, setProfileData] = useState({
        username: '',
        email: '',
        phone: '',
    });

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = () => {
        const storedProfileData = JSON.parse(localStorage.getItem('profile'));
        if (storedProfileData) {
            setUserProfile(storedProfileData);
            console.log("storedProfileData",storedProfileData)
            // Ensure user_id is explicitly set
            setProfileData({
                username: storedProfileData.username,
                email: storedProfileData.email,
                phone: storedProfileData.phone,
                user_id: storedProfileData.user_id // ✅ Set user_id correctly
            });
    
            form.setFieldsValue({
                username: storedProfileData.username,
                email: storedProfileData.email,
                phone: storedProfileData.phone,
            });
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOk = () => {
        setLoading(true);
    
        form.validateFields()
            .then(values => {
                const updatedProfileData = {
                    ...values,
                    user_id: profileData?.user_id // ✅ Ensure user_id is included
                };
    
                const formDataToSend = new FormData();
                formDataToSend.append('user_name', updatedProfileData.username);
                formDataToSend.append('user_email', updatedProfileData.email);
                formDataToSend.append('user_phone', updatedProfileData.phone);
                formDataToSend.append('user_id', updatedProfileData.user_id); // ✅ Now user_id is included
                formDataToSend.append('action', "update");
                formDataToSend.append('admin_token', 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH');
    
                post('/user.php', formDataToSend)
                    .then(response => {
                        console.log('Data Updated successfully:', response);
                        message.success('Data Updated successfully');
                        // ✅ Store the updated profile, ensuring user_id is included
                        localStorage.setItem('profile', JSON.stringify(updatedProfileData));
                        console.log("updatedProfileData",updatedProfileData)
                        setProfileData(updatedProfileData); // ✅ Ensure state is updated properly
                        setIsModalOpen(false);
                    })
                    .catch(error => {
                        console.error('Error updating data:', error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                setLoading(false);
            });
    };
    return (
        <section className='ProfileEdit'>
            <div className='ProfileEdit-con'>
                <div className='ProfileEdit-con-heading'>
                    <h2>Edit Profile Details</h2>
                </div>
                <div className='ProfileEdit-con-inputs'>
                    <Form layout="vertical" initialValues={profileData} form={form}>
                        <Form.Item name="username" label="First Name">
                            <Input type="text" placeholder="Enter Name" disabled />
                        </Form.Item>
                        <Form.Item name="email" label="Email">
                            <Input type="text" placeholder="Enter Email" disabled />
                        </Form.Item>
                        <Form.Item name="phone" label="Mobile Number">
                            <Input type="number" placeholder="Enter Contact" disabled />
                        </Form.Item>
                    </Form>
                </div>
                <div className='ProfileEdit-btn'>
                    <button onClick={showModal}>
                        Update Profile
                    </button>
                </div>
            </div>

            <Modal
                visible={isModalOpen}
                onCancel={handleCancel}
                confirmLoading={loading} // Attach loading state to OK button
                onOk={handleOk}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="username"
                        label="First Name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        <Input type="text" placeholder="Enter Name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input type="text" placeholder="Enter Email" />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Mobile Number"
                        rules={[
                            { required: true, message: 'Please input your phone number!' },
                            { pattern: /^[0-9]{10}$/, message: 'Phone number must be exactly 10 digits!' }
                        ]}
                    >
                        <Input type="number" placeholder="Enter Contact" />
                    </Form.Item>
                </Form>
            </Modal>

        </section>
    );
};

export default ProfileEdit;
