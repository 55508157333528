import React, { useEffect, useState } from "react";
import "../../Css/YourOrder.css";
import "../../Css/AddtoCart.css";
import { useCartContext } from "../../context/addToCart";
import { useSelector } from "react-redux";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import { message } from 'antd';
import { useNavigate } from "react-router-dom";
import empty_product from "../../Assert/empty-product.webp";
import { FaMinus } from "react-icons/fa6";
import { get } from "../../apiService";
const PayOrder = ({ selectedAddress }) => {
  const navigate = useNavigate();
  const { cartValue, setCartCount, setCartValue } = useCartContext();
  const { token } = useSelector((state) => state.auth);
  const [productList, setProductList] = useState([]);
  const profileData = JSON.parse(localStorage.getItem('profile'));
  const [address, setAddress] = useState(null);
console.log("profileData",profileData)
  useEffect(() => {
    if (cartValue?.length > 0) {
      const productData = cartValue.map(item => ({
        product_id: item.productid,
        product_qty: item.productQuantity,
        product_price: item.product_price,
        color_id: item.colorid,
        size_id: item.sizeid,
      }));
      setProductList(productData);
    }
  }, [cartValue]);

  const removeCartData = (index) => {
    setCartValue((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      addtoLocalStorage(updatedData);
      setCartCount(updatedData?.length);
      return updatedData;
    });
  };

  const addtoLocalStorage = (data) => {
    localStorage.setItem("cart", JSON.stringify(data));
  };

  const fetchDataAdd = async () => {
    const tokenid = localStorage.getItem("access_token");
    try {
      const response = await get(`/address.php?action=fetchAddresses&token=${tokenid}`);
      const addressListData = response.data.data;
      const selected = addressListData.filter(address => address.action === "1");
      setAddress(selected[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchDataAdd();
  }, []);

  const error = () => {
    if (!token) {
      message.error('Please log in first to place the order.');
    } else if (selectedAddress == null) {
      message.error('Please select a delivery address.');
    }
  };
  const finalSubtotal = cartValue.reduce((acc, product) => acc + parseFloat(product.product_price) * product.productQuantity, 0);
  const finalTotal = cartValue.reduce((acc, product) => acc + parseFloat(product.fixedPrice) * product.productQuantity, 0);
  console.log("sknksdsdlnlsnlcsndcnslndlndsldnlsdncldsncns", finalTotal)

  const deliveryCharges = 0; // Delivery is free
  const totalTax = 0; // Tax is included in the price
  const totalAmount = finalSubtotal + deliveryCharges + totalTax;
  const totalSave = finalTotal - finalSubtotal;
  const formattedAmount = totalAmount.toFixed(2);
  const formattedSaveAmount = totalSave.toFixed(2);

  return (
    <div className="YourOrder-Con-Main">
      <div className="YourOrder-Con-head-Main">
        <h2>Your Order</h2>
      </div>
      {
        cartValue?.length <= 0 ? (
          <>
            <div className="empty-product-check-out-box">
              <img src={empty_product} alt="" />
              <h5> Add Product Into Cart</h5>
            </div>
          </>
        ) : (
          <>
            <div className="YourOrder-Con-Title-Main">
              <h2>Product</h2>
              <h2>Total</h2>
            </div>
            <div className="Product-addedCart-Side-Bar">
              {cartValue.map((v, i) => (
                <div className="Product-addedCart-Side-details" key={i}>
                  <div className="Product-addedCart-Image-con">
                    <div className="Product-addedCart-Image">
                      <img src={v.productImage} alt={v.productName} />
                    </div>
                  </div>
                  <div className="Product-details-addedCart-price-name">
                    <h2>{v.productName}</h2>
                    <h3><span>Size : </span>{v.productSize}</h3>
                    <h3><span>Quantity : </span>{v.productQuantity}</h3>
                    <h3><span>Price : </span>₹ {v.product_price}</h3>
                  </div>
                  <div className="Product-details-addedCart-cancel-btn">
                    <div className="Product-details-addedCart-cancel-btn-con" onClick={() => removeCartData(i)}>
                      <RxCross2 />
                    </div>
                  </div>
                </div>
              ))}
              <div className='YourOrder-Con-details-Main'>
                <div className='YourOrder-Con-details-text'>
                  <h2 className='YourOrder-total'>Total Amount</h2>
                </div>
                <div className="main-total-amount-div">
                  <h2 className="main-total-amount">₹ {finalTotal.toFixed(2)}</h2>
                </div>
              </div>
              <div className='YourOrder-Con-details-Main'>
                <div className='YourOrder-Con-details-text'>
                  <h2 className='YourOrder-total save-amount-text'>Offer</h2>
                </div>
                <h2 className="save-amount"> <span><FaMinus /></span> ₹ {formattedSaveAmount}</h2>
              </div>
              <div className='YourOrder-Con-details-Main'>
                <div className='YourOrder-Con-details-text'>
                  <h2 className='YourOrder-total'>Discount Amount</h2>
                </div>
                <h2>₹ {formattedAmount}</h2>
              </div>

            </div>
            <div className="Product-details-Addedcart-btn">
              <div className="Product-details-Addedcart-btn-ViewCart">
                {token && selectedAddress ? (
                  <form method="post" action="https://skdm.in/server/kataria/api/index.php">
                    <input type="hidden" name="productList" value={JSON.stringify(productList)} />
                    <input type="hidden" name="name" value={profileData.username} />
                    <input type="hidden" name="address_id" value={selectedAddress.address_id} />
                    <input type="hidden" name="user_id" value={profileData.user_id} />
                    <input type="hidden" name="phone" value={profileData.phone} />
                    <input type="hidden" name="email" value={profileData.email} />
                    <input type="hidden" name="delivery_charges" value={deliveryCharges.toFixed(2)} />
                    <input type="hidden" name="subtotal" value={finalSubtotal.toFixed(2)} />
                    <input type="hidden" name="amount" value={formattedAmount} />
                    <input type="hidden" name="tax_amount" value="100" />
                    <input type="hidden" name="successpage" value={`${window.location.origin}/success-page`} />
                    <input type="hidden" name="failpage" value={`${window.location.origin}/fail-page`} />
                    <button type="submit">Place Your Order and Pay</button>
                  </form>
                ) : (
                  <button onClick={error}>Place Your Order and Pay</button>
                )}
              </div>
            </div>
          </>
        )
      }

    </div>
  );
};

export default PayOrder;
